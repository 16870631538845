import React, { Fragment, useEffect, useContext } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Layout from "layout/Layout"
import Container from "layout/Container"

import styles from "./utils/enrollmentComplete.module.scss"
import { useEnrollmentCompleteImages } from "./hooks/useEnrollmentCompleteImages"
import { AppContext } from "../../../context/AppContext"

const Completed = (props) => {
  const { pageContext } = props
  const { module, content } = pageContext
  const data = useEnrollmentCompleteImages()

  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    const restartDetails = async () => {
      await dispatch({ type: "RESET_DETAILS" })
    }
    restartDetails()
  }, [dispatch])

  return (
    <Layout
      title={module.title || "Thank you"}
      seoTitle={module.seoTitle}
      pageContext={pageContext}
    >
      <Container isCentered>
        <div className={classNames("columns", styles["column"])}>
          {content.icon && (
            <div className="column is-4-desktop is-12-mobile">
              <figure className={classNames("image mx-3")}>
                <Img
                  fluid={data[content.icon].childImageSharp.fluid}
                  alt={`${module.name} Complete`}
                  className={styles["image"]}
                />
              </figure>
            </div>
          )}
          <div className="column">
            <Fragment>
              <p className="my-1 mb-2">
                We have sent you an email regarding your enrollment. Should you
                wish to change some of your answers, please email us at{" "}
                <a
                  href={`mailto:${process.env.GATSBY_AWS_SES_EMAIL_TO}`}
                  className="has-text-weight-bold"
                >
                  {process.env.GATSBY_AWS_SES_EMAIL_TO}
                </a>
                .
              </p>
            </Fragment>
          </div>
        </div>
        <center>
          <Link
            to={`/self/history`}
            className="mt-2 button is-primary is-medium"
          >
            Request Teleconsult
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default Completed
